import React from "react";
import styled from "styled-components";
import { useLanguage } from "../../context/LanguageContext";  // Use context for language
import Flag from 'react-world-flags';  // Assuming you're using 'react-world-flags' for flags

const LanguageToggle = () => {
  const { language, toggleLanguage } = useLanguage();  // Use language context

  return (
    <ToggleWrapper onClick={toggleLanguage}>
      <ToggleSwitch className={language === 'en' ? 'en' : 'fr'}>
        <FlagWrapper className={language === 'en' ? 'left' : 'right'}>
          {language === 'en' ? <Flag code="GB" /> : <Flag code="FR" />}
        </FlagWrapper>
        <SwitchButton className={language === 'en' ? 'right' : 'left'}>
          {language === 'en' ? 'EN' : 'FR'}
        </SwitchButton>
      </ToggleSwitch>
    </ToggleWrapper>
  );
};

export default LanguageToggle;

const ToggleWrapper = styled.li`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const ToggleSwitch = styled.div`
  position: relative;
  width: 80px;
  height: 40px;
  background: #ccc;
  border-radius: 20px;
  transition: background 0.3s;

  &.en {
    background: #0b093b;
  }

  &.fr {
    background: #3C3C3CFF;
  }
`;

const FlagWrapper = styled.div`
  position: absolute;
  top: 5px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.left {
    left: 7px;
  }

  &.right {
    right: 7px;
  }

  img {
    width: 90%;
    height: auto;
  }
`;

const SwitchButton = styled.div`
  position: absolute;
  top: 5px;
  padding-top:4px;
  width: 30px;
  height: 30px;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  transition: left 0.3s, right 0.3s;

  &.left {
    left: 5px;
  }

  &.right {
    right: 5px;
  }
`;
