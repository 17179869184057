import React from "react";
import { Link } from "react-scroll";
import styled from "styled-components";


export default function FullButton({ title, to, border }) {
  return (
    <Link to={to} smooth={true} offset={-80}>
      <Wrapper className="animate pointer radius8" border={border}>
        {title}
      </Wrapper>
    </Link>
  );
}

const Wrapper = styled.button`
  border: 1px solid ${(props) => (props.border ? "#707070" : "#1f1b59")};
  background-color: ${(props) => (props.border ? "transparent" : "#1f1b59")};
  width: 100%;
  padding: 15px;
  outline: none;
  color: ${(props) => (props.border ? "#707070" : "#fff")};
  :hover {
    background-color: ${(props) => (props.border ? "transparent" : "#27254BFF ")};
    border: 1px solid #27254BFF;
    color: ${(props) => (props.border ? "#1f1b59" : "#fff")};
  }
`;
