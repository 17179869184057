import React, { useState } from "react";
import styled from "styled-components";
import Slider from "react-slick";


// Components
import PricingTable from "../Elements/PricingTable";

export default function Pricing() {
  const [filter, setFilter] = useState("All");

  const pricingData = {
    "Offres Web": [
      {
        icon: "webDevelopment", // Nom de l'icône mis à jour
        price: "200 TND",
        title: "Site Web Portfolio",
        text: "Un site web simple pour présenter votre travail, y compris l'hébergement, le serveur et le domaine.",
        offers: [
          { name: "Section Galerie", cheked: true },
          { name: "Design Responsive", cheked: true },
          { name: "Formulaire de Contact", cheked: true },
          { name: "1 Révision", cheked: true },
          { name: "Hébergement & Domaine", cheked: true },
        ],
      },
      {
        icon: "webDevelopment", // Nom de l'icône mis à jour
        price: "500 TND",
        title: "Site Web de Base",
        text: "Un site web simple et efficace pour votre entreprise avec les fonctionnalités essentielles.",
        offers: [
          { name: "Design Responsive", cheked: true },
          { name: "SEO de Base", cheked: true },
          { name: "Formulaire de Contact", cheked: true },
          { name: "Hébergement & Domaine", cheked: true },
        ],
      },
      {
        icon: "webDevelopment", // Nom de l'icône mis à jour
        price: "2000 TND",
        title: "Site Web E-commerce",
        text: "Lancez votre boutique en ligne avec les fonctionnalités essentielles pour la gestion des produits.",
        offers: [
          { name: "Gestion des Produits", cheked: true },
          { name: "Intégration du Paiement", cheked: true },
          { name: "Système de Livraison Automatisé", cheked: true },
          { name: "Design Personnalisé", cheked: true },
          { name: "Hébergement & Domaine", cheked: true },
        ],
      },
    ],
    "Offres Mobiles": [
      {
        icon: "mobileDevelopment", // Nom de l'icône mis à jour
        price: "300 TND",
        title: "Application Mobile Simple",
        text: "Une application mobile simple pour iOS ou Android avec des fonctionnalités de base.",
        offers: [
          { name: "iOS ou Android", cheked: true },
          { name: "UI/UX de Base", cheked: true },
        ],
      },
      {
        icon: "mobileDevelopment", // Nom de l'icône mis à jour
        price: "1000 TND",
        title: "Application Mobile Standard",
        text: "Une application mobile avec des fonctionnalités améliorées et une interface conviviale.",
        offers: [
          { name: "iOS & Android", cheked: true },
          { name: "Notifications Push", cheked: true },
          { name: "Support de Base", cheked: true },
        ],
      },
      {
        icon: "mobileDevelopment", // Nom de l'icône mis à jour
        price: "2000 TND",
        title: "Application Mobile Avancée",
        text: "Une solution mobile complète pour iOS et Android avec des intégrations personnalisées.",
        offers: [
          { name: "iOS & Android", cheked: true },
          { name: "Intégrations Personnalisées", cheked: true },
          { name: "UI/UX Avancée", cheked: true },
          { name: "Support Premium", cheked: true },
        ],
      },
    ],
    "Solutions Personnalisées": [
      {
        icon: "personalizedApps", // Nom de l'icône mis à jour
        price: "Sur demande",
        title: "Solution Personnalisée",
        text: "Solutions sur mesure combinant web et mobile, incluant des fonctionnalités et des intégrations personnalisées.",
        offers: [
          { name: "Web & Application Mobile", cheked: true },
          { name: "Fonctionnalités Personnalisées", cheked: true },
          { name: "Intégration d'IA", cheked: true },
          { name: "Support Premium", cheked: true },
        ],
      },
    ],
    "Offres Design": [
      {
        icon: "design", // Nom de l'icône mis à jour
        price: "100 TND",
        title: "Design UI/UX",
        text: "Obtenez un design moderne et intuitif pour votre projet web ou mobile.",
        offers: [
          { name: "Design Responsive", cheked: true },
          { name: "Recherche Utilisateur", cheked: true },
          { name: "Wireframes & Prototypes", cheked: true },
          { name: "2 Révisions de Design", cheked: true },
        ],
      },
    ],
  };

  const filters = ["Tous", "Offres Web", "Offres Mobiles", "Solutions Personnalisées", "Offres Design"];

  const handleFilter = (category) => {
    setFilter(category);
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    autoplay: true, // Autoplay activé
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 860,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 560,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Wrapper id="tarifs">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Plans Tarifs AppTap</h1>
            <p className="font13">
              Choisissez le plan au prix fixe qui convient à votre entreprise. Que vous ayez besoin de développement web, d'applications mobiles ou de solutions personnalisées, nous proposons des services sur mesure avec des prix transparents en Dinars Tunisiens (TND).
            </p>
          </HeaderInfo>

          {/* Filter Buttons */}
          <FilterWrapper>
            {filters.map((category, index) => (
              <FilterButton key={index} onClick={() => handleFilter(category)} active={filter === category}>
                {category}
              </FilterButton>
            ))}
          </FilterWrapper>

          {/* React Slick Slider for Pricing Tables */}
          <Slider {...sliderSettings}>
              {Object.keys(pricingData)
                .filter((key) => filter === "All" || key === filter)
                .map((key) =>
                  pricingData[key].map((plan, i) => (
                  <TableBox key={i}>
                    <PricingTable
                      icon={plan.icon}
                      price={plan.price}
                      title={plan.title}
                      text={plan.text}
                      offers={plan.offers}
                      action={plan.action}
                    />
                  </TableBox>
                ))
              )}
          </Slider>
        </div>
      </div>
    </Wrapper>
  );
}

// Styling for the Wrapper and elements
const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;

const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const FilterWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px; /* Slightly smaller margin */
  flex-wrap: wrap; /* Allows buttons to wrap on smaller screens */
`;

const FilterButton = styled.div`
  margin: 0 5px; /* Reduced space between buttons */
  border-radius: 25px; /* Slightly smaller border-radius */
  background-color: ${(props) => (props.active ? "#110E3AFF" : "#322E68FF")}; /* Blue by default, dark blue when active */
  width: auto; /* Adjust button width to fit text */
  margin-bottom: 5px; /* Slightly smaller margin */
  padding: 10px 20px; /* Reduced padding for smaller buttons */
  text-align: center; /* Center text */
  outline: none;
  color: ${(props) => (props.active ? "#f2b300" : "#fff")}; /* White or yellow text */
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition */

  /* Responsive styles */
  font-size: 1rem; /* Default font size */
  
  @media (max-width: 768px) {
    font-size: 0.9rem; /* Slightly smaller font on tablets */
    padding: 8px 16px; /* Adjust padding */
  }

  @media (max-width: 480px) {
    font-size: 0.8rem; /* Even smaller font on mobile */
    padding: 6px 12px; /* Further adjust padding */
  }

  :hover {
    background-color: ${(props) => (props.active ? "#1A1646FF" : "#2A285EFF")}; /* Darker blue on hover */
  }
`;




const TableBox = styled.div`
  display: flex;
  min-height: 400px;  /* Ensures uniform height */
  width: 100%;
  justify-content: center;
  align-items: center;
`;