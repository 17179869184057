import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaEnvelope, FaPhone, FaMapMarkerAlt } from 'react-icons/fa'; // Import des icônes de react-icons
// Assets
import LogoImg from "../../assets/img/logob.png"; // Mettez à jour ce chemin vers votre logo

export default function Contact() {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <Wrapper>
      <div className="darkBg">
        <div className="container">
          <InnerWrapper className="flexSpaceCenter" style={{ padding: "20px 0" }}>
            <Column>
              <Link className="flexCenter animate pointer" to="home" smooth={true} offset={-80}>
                <img src={LogoImg} alt="Logo AppTap" style={{ height: '40px' }} /> {/* Ajustez la hauteur selon les besoins */}
              </Link>
              <Description className="font13 whiteColor">
                AppTap est votre solution ultime pour des applications innovantes, apportant facilité et efficacité à portée de main.
              </Description>
            </Column>
            <Column>
              <ContactDetails>
                <ContactItem>
                  <FaEnvelope style={{ marginRight: '6px' }} />
                  <a href="mailto:contact@apptap.tn">contact@apptap.tn</a>
                </ContactItem>
                <ContactItem>
                  <FaPhone style={{ marginRight: '6px' }} />
                  <a href="tel:+21699472230">+216 99 472 230</a>
                </ContactItem>
                <ContactItem>
                  <FaMapMarkerAlt style={{ marginRight: '6px' }} />
                  Gabès, Tunisie
                </ContactItem>
              </ContactDetails>
              <SocialIcons>
                <a href="https://www.facebook.com/profile.php?id=61558055255867" target="_blank" rel="noopener noreferrer">
                  <FaFacebookF />
                </a>
                <a href="https://www.instagram.com/apptap_dev/" target="_blank" rel="noopener noreferrer">
                  <FaInstagram />
                </a>
                <a href="https://www.linkedin.com/company/apptapdev" target="_blank" rel="noopener noreferrer">
                  <FaLinkedinIn />
                </a>
              </SocialIcons>
            </Column>
            <Column>
              <Link className="whiteColor animate pointer font13" to="home" smooth={true} offset={-80}>
                Retour en haut
              </Link>
            </Column>
          </InnerWrapper>
          <BottomWrapper className="flexSpaceCenter">
            <StyleP className="whiteColor font13">
              © {getCurrentYear()} - <span className="purpleColor font13">AppTap</span> Tous droits réservés
            </StyleP>
          </BottomWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const BottomWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  padding-bottom: 10px; 
`;

const StyleP = styled.p`
  text-align: center;

  @media (max-width: 550px) {
    margin: 10px 0;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;

  @media (max-width: 768px) {
    align-items: center;
    text-align: center;
  }
`;

const Description = styled.p`
  margin-top: 5px;
  max-width: 300px;
`;

const ContactDetails = styled.div`
  color: #fff;
  margin-bottom: 10px;
`;

const ContactItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 5px;

  a {
    color: #fff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 10px;

  a {
    color: #fff;
    font-size: 20px;

    &:hover {
      opacity: 0.8;
    }
  }
`;
