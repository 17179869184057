import React from "react";
import styled from "styled-components";
// Components
import ClientSlider from "../Elements/ClientSlider";
import ServiceBox from "../Elements/ServiceBox";
import FullButton from "../Buttons/FullButton";
// Assets
import AddImage1 from "../../assets/img/add/1.png";

export default function Services() {
  return (
    <Wrapper id="services" name="services">
      <div className="lightBg" style={{ padding: "50px 0" }}>
        <div className="container">
          <ClientSlider />
        </div>
      </div>
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Les Services Innovants d'AppTap</h1>
            <p className="font13">
              Chez AppTap, nous proposons une large gamme de services pour vous aider à créer les meilleures solutions pour votre entreprise.
              <br />
              Notre équipe est spécialisée dans le Design, le Développement Web et Mobile, ainsi que la création d'Applications Personnalisées adaptées à vos besoins.
            </p>
          </HeaderInfo>
          <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
              <ServiceBox
                icon="design"
                title="Design"
                subtitle="Des designs beaux, fonctionnels et conviviaux qui résonnent avec votre public."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="webDevelopment"
                title="Développement Web"
                subtitle="Des sites web performants créés en utilisant les dernières technologies et meilleures pratiques."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="mobileDevelopment"
                title="Développement Mobile"
                subtitle="Des applications mobiles pour iOS et Android pour améliorer la mobilité de votre entreprise."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="personalizedApps"
                title="Applications Personnalisées"
                subtitle="Des applications sur mesure pour répondre à vos exigences et objectifs uniques."
              />
            </ServiceBoxWrapper>
          </ServiceBoxRow>
        </div>
        <div className="lightBg">
          <div className="container">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                <h4 className="font15 semiBold">À propos d'Apptap</h4>
                <h2 className="font40 extraBold">Innovation en Solutions Web, Mobiles et IA</h2>
                <p className="font12">
                  Chez Apptap, nous nous spécialisons dans la création de solutions web, mobiles et alimentées par l'IA, adaptées à vos besoins. Que vous ayez besoin d'une application conviviale, d'une plateforme web puissante ou de systèmes personnalisés basés sur l'IA, notre équipe transforme votre vision en expériences numériques fluides. Construisons quelque chose d'extraordinaire ensemble.
                </p>
                <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0" }}>
                  <div style={{ width: "190px" }}>
                    <FullButton title="Contactez-nous" to="contact" />
                  </div>
                </ButtonsRow>
              </AddLeft>
              <AddRight>
                <AddRightInner>
                  <div className="flexNullCenter">
                    <AddImgWrapp1 className="flexCenter">
                      <img src={AddImage1} alt="bureau" />
                    </AddImgWrapp1>
                  </div>
                </AddRightInner>
              </AddRight>
            </Advertising>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}


const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  align-items: center;  // Center content horizontally
  justify-content: center;  // Center content vertically
  text-align: center;
  
  @media (max-width: 860px) {
    width: 100%;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: center;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  display: flex;
  justify-content: center; /* Centers the content horizontally */
  position: absolute;
  right: 0;
  @media (max-width: 860px) {
    width: 50%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;

const AddImgWrapp1 = styled.div`
  width: 48%;
  display: flex;
  justify-content: center; /* Centers the image container horizontally */
  margin: 0 auto; /* Ensures the image is centered within the wrapper */
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;

const AddRightInner = styled.div`
  width: 100%;
`;
