import React from "react";
import styled from "styled-components";
import FullButton from "../Buttons/FullButton";
import { FaPalette, FaCode, FaMobileAlt, FaRegLightbulb } from "react-icons/fa"; // Import icons from react-icons

export default function PricingTable({ icon, price, title, text, offers }) {
  let getIcon;



  switch (icon) {
    case "design":
      getIcon = <FaPalette color="#FF6347" size={50} />;
      break;
    case "webDevelopment":
      getIcon = <FaCode color="#4682B4" size={50} />;
      break;
    case "mobileDevelopment":
      getIcon = <FaMobileAlt color="#32CD32" size={50} />;
      break;
    case "personalizedApps":
      getIcon = <FaRegLightbulb color="#FFD700" size={50} />;
      break;
    default:
      getIcon = <FaPalette color="#FF6347" size={50} />;
      break;
  }

  return (
    <Wrapper className="whiteBg radius8 shadow">
      <div className="flexSpaceCenter">
        {getIcon}
        <p className="font30 extraBold">{price}</p>
      </div>
      <div style={{ margin: "30px 0" }}>
        <h4 className="font30 extraBold">{title}</h4>
        <p className="font13">{text}</p>
      </div>
      <div>
        {offers
          ? offers.map((item, index) => (
              <div className="flexNullCenter" style={{ margin: "15px 0" }} key={index}>
                <div style={{ position: "relative", top: "-1px", marginRight: "15px" }}>
                  {item.cheked ? <div style={{ minWidth: "20px" }}>✔</div> : <div style={{ minWidth: "20px" }}></div>}
                </div>
                <p className="font20 extraBold">{item.name}</p>
              </div>
            ))
          : null}
      </div>
      <BtnWrapper>
        <FullButton title="Get Started" to="contact" />
      </BtnWrapper>
    </Wrapper>
  );
}

// Styling for the Wrapper and Button
const Wrapper = styled.div`
  width: 100%;
  text-align: center;
  padding: 20px 30px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 600px;
`;

const BtnWrapper = styled.div`
  max-width: 190px;
  margin: 0 auto;
  
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
