import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLanguage } from "../../context/LanguageContext";
import { Link } from "react-scroll";
import CloseIcon from "../../assets/svg/CloseIcon";
import LogoImg from "../../assets/img/logob.png";
import Flag from 'react-world-flags'; // For flags (Install react-world-flags package)

export default function Sidebar({ sidebarOpen, toggleSidebar }) {
  const { language, toggleLanguage } = useLanguage();

  // Track if the component has mounted to prevent issues with lifecycle methods
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true); // Only render after the component has mounted to avoid errors in strict mode
  }, []);

  if (!mounted) return null; // Return nothing until the component has mounted

  return (
    <Wrapper className="animate darkBg" sidebarOpen={sidebarOpen}>
      <SidebarHeader className="flexSpaceCenter">
        <div className="flexNullCenter">
          <img src={LogoImg} alt="AppTap Logo" style={{ height: '40px' }} />
          <h1 className="whiteColor font20" style={{ marginLeft: "15px" }}>Apptap</h1>
        </div>
        <CloseBtn onClick={() => toggleSidebar(!sidebarOpen)} className="animate pointer">
          <CloseIcon />
        </CloseBtn>
      </SidebarHeader>

      <UlStyle className="flexNullCenter flexColumn">
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="home"
            spy={true}
            smooth={true}
            offset={-60}
          >
            {language === 'en' ? 'Home' : 'Accueil'}
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="services"
            spy={true}
            smooth={true}
            offset={-60}
          >
            {language === 'en' ? 'Services' : 'Services'}
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="projects"
            spy={true}
            smooth={true}
            offset={-60}
          >
            {language === 'en' ? 'Projects' : 'Projets'}
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="team"
            spy={true}
            smooth={true}
            offset={-60}
          >
            {language === 'en' ? 'Team' : 'Équipe'}
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="pricing"
            spy={true}
            smooth={true}
            offset={-60}
          >
            {language === 'en' ? 'Pricing' : 'Tarifs'}
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="contact"
            spy={true}
            smooth={true}
            offset={-60}
          >
            {language === 'en' ? 'Contact' : 'Contact'}
          </Link>
        </li>

        {/* Language Toggle in a list item */}
        <li className="semiBold font15 pointer">
          <LanguageSwitcher onClick={toggleLanguage}>
            <FlagWrapper>
              <Flag code={language === 'en' ? "FR" : "GB"} />
            </FlagWrapper>
            <span>{language === 'en' ? 'Français' : 'English'}</span>
          </LanguageSwitcher>
        </li>
      </UlStyle>
    </Wrapper>
  );
}

const Wrapper = styled.nav`
  width: 400px;
  height: 100vh;
  position: fixed;
  top: 0;
  padding: 0 30px;
  right: ${(props) => (props.sidebarOpen ? "0px" : "-400px")};
  z-index: 9999;
  background-color: #333;
  transition: right 0.3s ease-in-out;
  @media (max-width: 400px) {
    width: 100%;
  }
`;

const SidebarHeader = styled.div`
  padding: 20px 0;
`;

const CloseBtn = styled.button`
  border: 0;
  outline: none;
  background-color: transparent;
  padding: 10px;
`;

const UlStyle = styled.ul`
  padding: 40px;
  list-style: none;
  li {
    margin: 20px 0;
  }
`;

const LanguageSwitcher = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  color: #fff;
  font-size: 16px;
  padding: 10px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 10px;

  span {
    font-size: 16px;
  }
`;

const FlagWrapper = styled.div`
  width: 30px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: auto;
  }
`;
