import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
// Assets
import Logo01 from "../../assets/img/clients/html.svg";
import Logo02 from "../../assets/img/clients/css.svg";
import Logo03 from "../../assets/img/clients/js.svg";
import Logo04 from "../../assets/img/clients/react.svg";
import Logo05 from "../../assets/img/clients/node.svg";
import Logo06 from "../../assets/img/clients/flutter.svg";
import Logo07 from "../../assets/img/clients/dart.svg";
import Logo08 from "../../assets/img/clients/django.svg";
import Logo09 from "../../assets/img/clients/wordpress.svg";
import Logo10 from "../../assets/img/clients/flask.svg";
import Logo11 from "../../assets/img/clients/php.svg";
import Logo12 from "../../assets/img/clients/mongodb.svg";
import Logo13 from "../../assets/img/clients/python.svg";
import Logo14 from "../../assets/img/clients/bootstrap.svg";
import Logo15 from "../../assets/img/clients/firebase.svg";





export default function ClientSlider() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 1300, // Adjust autoplay speed in milliseconds (1 second)
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <Slider {...settings}>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={Logo01} alt="logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={Logo02} alt="logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={Logo03} alt="logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={Logo04} alt="logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={Logo05} alt="logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={Logo06} alt="logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={Logo07} alt="logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={Logo08} alt="logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={Logo09} alt="logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={Logo10} alt="logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={Logo11} alt="logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={Logo12} alt="logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={Logo13} alt="logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={Logo14} alt="logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={Logo15} alt="logo" />
        </LogoWrapper>
      </Slider>
    </div>
  );
}

const LogoWrapper = styled.div`
  width: 100%;
  height: 100px;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
const ImgStyle = styled.img`
  width: 100%;
  height: 100%;
  padding: 10%;
`;