import React, { useEffect } from "react";
import { useLanguage } from "../context/LanguageContext";

const Landing = () => {
  // Get the current language from context
  const { language } = useLanguage();

  // Ensure a default language is provided (fallback to 'en')
  const currentLanguage = language || 'en';

  // Dynamically import components based on the selected language
  const TopNavbar = currentLanguage === 'en' 
    ? require("../components/Nav/TopNavbar").default 
    : require("../componentsfr/Nav/TopNavbar").default;
  const Header = currentLanguage === 'en' 
    ? require("../components/Sections/Header").default 
    : require("../componentsfr/Sections/Header").default;
  const Services = currentLanguage === 'en' 
    ? require("../components/Sections/Services").default 
    : require("../componentsfr/Sections/Services").default;
  const Projects = currentLanguage === 'en' 
    ? require("../components/Sections/Projects").default 
    : require("../componentsfr/Sections/Projects").default;
  const Blog = currentLanguage === 'en' 
    ? require("../components/Sections/Blog").default 
    : require("../componentsfr/Sections/Blog").default;
  const Pricing = currentLanguage === 'en' 
    ? require("../components/Sections/Pricing").default 
    : require("../componentsfr/Sections/Pricing").default;
  const Contact = currentLanguage === 'en' 
    ? require("../components/Sections/Contact").default 
    : require("../componentsfr/Sections/Contact").default;
  const Footer = currentLanguage === 'en' 
    ? require("../components/Sections/Footer").default 
    : require("../componentsfr/Sections/Footer").default;

  useEffect(() => {
    // Create Tawk.to script element
    const scriptId = "tawk-script";
    if (!document.getElementById(scriptId)) {
      const script = document.createElement("script");
      script.id = scriptId;
      script.type = "text/javascript";
      script.async = true;
      script.src = "https://embed.tawk.to/6733e40f4304e3196ae13c1c/1ichb5n7f";
      script.charset = "UTF-8";
      script.setAttribute('crossorigin', '*');
  
      // Add the script to the document
      const scriptContainer = document.getElementsByTagName("script")[0];
      scriptContainer.parentNode.insertBefore(script, scriptContainer);
    }

    // Cleanup function
    return () => {
      const script = document.getElementById(scriptId);
      if (script) {
        script.parentNode.removeChild(script);
      }
    };
  }, []);

  return (
    <div>
      {/* Render components dynamically */}
      <TopNavbar />
      <Header />
      <Services />
      <Projects />
      <Blog />
      <Pricing />
      <Contact />
      <Footer />
    </div>
  );
};

export default Landing;
