import React from "react";
import styled from "styled-components";
// Components
import ClientSlider from "../Elements/ClientSlider";
import ServiceBox from "../Elements/ServiceBox";
import FullButton from "../Buttons/FullButton";
// Assets
import AddImage1 from "../../assets/img/add/1.png";

export default function Services() {
  return (
    <Wrapper id="services" name="services">
      <div className="lightBg" style={{ padding: "50px 0" }}>
        <div className="container">
          <ClientSlider />
        </div>
      </div>
      <div className="whiteBg" style={{ padding: "60px 0" }}>
      <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">AppTap's Innovative Services</h1>
            <p className="font13">
              At AppTap, we offer a wide range of services to help you create the best solutions for your business.
              <br />
              Our team is skilled in Design, Web and Mobile Development, and creating Personalized Applications tailored to your needs.
            </p>
          </HeaderInfo>
          <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
              <ServiceBox
                icon="design"
                title="Design"
                subtitle="Beautiful, functional, and user-friendly designs that resonate with your audience."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="webDevelopment"
                title="Web Development"
                subtitle="High-performance websites built using the latest technologies and best practices."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="mobileDevelopment"
                title="Mobile Development"
                subtitle="Mobile applications for both iOS and Android to enhance your business's mobility."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="personalizedApps"
                title="Personalized Applications"
                subtitle="Custom-built applications to fit your unique business requirements and goals."
              />
            </ServiceBoxWrapper>
          </ServiceBoxRow>
        </div>
        <div className="lightBg">
          <div className="container">
            <Advertising className="flexSpaceCenter">
            <AddLeft>
              <h4 className="font15 semiBold">About Apptap</h4>
              <h2 className="font40 extraBold">Innovating Web, Mobile, and AI Solutions</h2>
              <p className="font12">
                At Apptap, we specialize in creating cutting-edge web, mobile, and AI-powered solutions tailored to your needs. Whether you need a user-friendly app, a powerful web platform, or custom AI-driven systems, our team transforms your vision into seamless digital experiences. Let’s build something extraordinary together.
              </p>
              <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0" }}>
                <div style={{ width: "190px" }}>
                  <FullButton title="Contact Us" to="contact" />
                </div>
              </ButtonsRow>
            </AddLeft>
              <AddRight>
                <AddRightInner>
                  <div className="flexNullCenter">
                    <AddImgWrapp1 className="flexCenter">
                      <img src={AddImage1} alt="office" />
                    </AddImgWrapp1>
                  </div>
                  
                </AddRightInner>
              </AddRight>
            </Advertising>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  align-items: center;  // Center content horizontally
  justify-content: center;  // Center content vertically
  text-align: center;
  
  @media (max-width: 860px) {
    width: 100%;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: center;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  display: flex;
  justify-content: center; /* Centers the content horizontally */
  position: absolute;
  right: 0;
  @media (max-width: 860px) {
    width: 50%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;

const AddImgWrapp1 = styled.div`
  width: 48%;
  display: flex;
  justify-content: center; /* Centers the image container horizontally */
  margin: 0 auto; /* Ensures the image is centered within the wrapper */
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;

const AddRightInner = styled.div`
  width: 100%;
`;
