import React, { useState } from "react";
import styled from "styled-components";
import Slider from "react-slick";


// Components
import PricingTable from "../Elements/PricingTable";

export default function Pricing() {
  const [filter, setFilter] = useState("All");

  const pricingData = {
    "Web Offers": [
      {
        icon: "webDevelopment", // Updated icon name
        price: "200 TND",
        title: "Portfolio Website",
        text: "A simple portfolio website to showcase your work, including hosting, server, and domain.",
        offers: [
          { name: "Gallery Section", cheked: true },
          { name: "Responsive Design", cheked: true },
          { name: "Contact Form", cheked: true },
          { name: "1 Revision", cheked: true },
          { name: "Hosting & Domain", cheked: true },
        ],
        },
      {
        icon: "webDevelopment", // Updated icon name
        price: "500 TND",
        title: "Basic Website",
        text: "A simple and effective website for your business with essential features.",
        offers: [
          { name: "Responsive Design", cheked: true },
          { name: "Basic SEO", cheked: true },
          { name: "Contact Form", cheked: true },
          { name: "Hosting & Domain", cheked: true },
        ],
      },
      {
        icon: "webDevelopment", // Updated icon name
        price: "2000 TND",
        title: "E-commerce Website",
        text: "Launch your online store with essential features for product management.",
        offers: [
          { name: "Product Management", cheked: true },
          { name: "Payment Gateway Integration", cheked: true },
          { name: "Automated Delivery System", cheked: true },
          { name: "Custom Design", cheked: true },
          { name: "Hosting & Domain", cheked: true },
        ],
      },
    ],
    "Mobile Offers": [
      {
        icon: "mobileDevelopment", // Updated icon name
        price: "300 TND",
        title: "Simple Mobile App",
        text: "A straightforward mobile app for iOS or Android with basic functionality.",
        offers: [
          { name: "iOS or Android", cheked: true },
          { name: "Basic UI/UX", cheked: true },
        ],
      },
      {
        icon: "mobileDevelopment", // Updated icon name
        price: "1000 TND",
        title: "Standard Mobile App",
        text: "A mobile app with enhanced features and a user-friendly interface.",
        offers: [
          { name: "iOS & Android", cheked: true },
          { name: "Push Notifications", cheked: true },
          { name: "Basic Support", cheked: true },
        ],
      },
      {
        icon: "mobileDevelopment", // Updated icon name
        price: "2000 TND",
        title: "Advanced Mobile App",
        text: "A comprehensive mobile solution for both iOS and Android with custom integrations.",
        offers: [
          { name: "iOS & Android", cheked: true },
          { name: "Custom Integrations", cheked: true },
          { name: "Advanced UI/UX", cheked: true },
          { name: "Premium Support", cheked: true },
        ],
      },
    ],
    "Customized Solutions": [
      {
        icon: "personalizedApps", // Updated icon name
        price: "On Demand",
        title: "Custom Solution",
        text: "Tailored solutions combining web and mobile, including custom features and integrations.",
        offers: [
          { name: "Web & Mobile App", cheked: true },
          { name: "Custom Features", cheked: true },
          { name: "AI Integration", cheked: true },
          { name: "Premium Support", cheked: true },
        ],
      },
    ],
    "Design Offers": [
      {
        icon: "design", // Updated icon name
        price: "100 TND",
        title: "UI/UX Design",
        text: "Get a modern and intuitive design for your web or mobile project.",
        offers: [
          { name: "Responsive Design", cheked: true },
          { name: "User Research", cheked: true },
          { name: "Wireframes & Prototypes", cheked: true },
          { name: "2 Design Revisions", cheked: true },
        ],
      },
    ],
  };


  const filters = ["All", "Web Offers", "Mobile Offers", "Customized Solutions", "Design Offers"];

  const handleFilter = (category) => {
    setFilter(category);
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 860,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 560,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Wrapper id="pricing">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">AppTap Pricing Plans</h1>
            <p className="font13">
              Choose the right fixed-price plan for your business. Whether you need web development, mobile apps, or customized solutions, 
              we offer tailored services with transparent pricing in Tunisian Dinars (TND).
            </p>
          </HeaderInfo>

          {/* Filter Buttons */}
          <FilterWrapper>
            {filters.map((category, index) => (
              <FilterButton key={index} onClick={() => handleFilter(category)} active={filter === category}>
                {category}
              </FilterButton>
            ))}
          </FilterWrapper>

          {/* React Slick Slider for Pricing Tables */}
          <Slider {...sliderSettings}>
              {Object.keys(pricingData)
                .filter((key) => filter === "All" || key === filter)
                .map((key) =>
                  pricingData[key].map((plan, i) => (
                  <TableBox key={i}>
                    <PricingTable
                      icon={plan.icon}
                      price={plan.price}
                      title={plan.title}
                      text={plan.text}
                      offers={plan.offers}
                      action={plan.action}
                    />
                  </TableBox>
                ))
              )}
          </Slider>
        </div>
      </div>
    </Wrapper>
  );
}

// Styling for the Wrapper and elements
const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;

const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const FilterWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px; /* Slightly smaller margin */
  flex-wrap: wrap; /* Allows buttons to wrap on smaller screens */
`;

const FilterButton = styled.div`
  margin: 0 5px; /* Reduced space between buttons */
  border-radius: 25px; /* Slightly smaller border-radius */
  background-color: ${(props) => (props.active ? "#110E3AFF" : "#322E68FF")}; /* Blue by default, dark blue when active */
  width: auto; /* Adjust button width to fit text */
  margin-bottom: 5px; /* Slightly smaller margin */
  padding: 10px 20px; /* Reduced padding for smaller buttons */
  text-align: center; /* Center text */
  outline: none;
  color: ${(props) => (props.active ? "#f2b300" : "#fff")}; /* White or yellow text */
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition */

  /* Responsive styles */
  font-size: 1rem; /* Default font size */
  
  @media (max-width: 768px) {
    font-size: 0.9rem; /* Slightly smaller font on tablets */
    padding: 8px 16px; /* Adjust padding */
  }

  @media (max-width: 480px) {
    font-size: 0.8rem; /* Even smaller font on mobile */
    padding: 6px 12px; /* Further adjust padding */
  }

  :hover {
    background-color: ${(props) => (props.active ? "#1A1646FF" : "#2A285EFF")}; /* Darker blue on hover */
  }
`;




const TableBox = styled.div`
  display: flex;
  min-height: 400px;  /* Ensures uniform height */
  width: 100%;
  justify-content: center;
  align-items: center;
`;