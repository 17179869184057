import React from "react";
import styled from "styled-components";
// Assets
import Screenshot1 from "../../assets/img/projects/barbaros.png";
import Screenshot2 from "../../assets/img/projects/keyanagency.png";
import Screenshot3 from "../../assets/img/projects/consultingllc.png";
import Screenshot4 from "../../assets/img/projects/eramsusproject.png";
import Screenshot5 from "../../assets/img/projects/oascanner.png";
import Screenshot6 from "../../assets/img/projects/portfolio.png";

export default function Projects() {
  const projects = [
    {
      img: Screenshot1,
      title: "Barbershop Barbaros",
      description: "Un salon de coiffure élégant et professionnel offrant des services de coiffure premium.",
      link: "https://barbaros-barbershop.com/",
    },
    {
      img: Screenshot2,
      title: "Keyan",
      description: "Keyan est une plateforme de commerce en ligne moderne offrant une expérience d'achat fluide.",
      link: "https://keyan.com.tn/",
    },
    {
      img: Screenshot3,
      title: "Consulting Group LLC",
      description: "Une société de conseil spécialisée dans les services de conseil en affaires et stratégie.",
      link: "https://keen-stroopwafel-8facc4.netlify.app/",
    },
    {
      img: Screenshot4,
      title: "WEN4SD",
      description: "Une plateforme promouvant des initiatives et des solutions de développement durable.",
      link: "https://wen4sd.com/",
    },
    {
      img: Screenshot5,
      title: "OA Scanner",
      description: "Une application de pointe pour le traitement et l'analyse des images satellites.",
      link: "https://oascanner.tn/",
      loginRequired: true, // Ajouter un indicateur pour indiquer que la connexion est requise
    },
    {
      img: Screenshot6,
      title: "Portfolio d'Ahmed Bensalem",
      description: "Un portfolio personnel présentant le travail et les projets d'Ahmed Bensalem.",
      link: "https://ahmedbensalem.com/",
    },
  ];

  const handleClick = (url, loginRequired) => {
    if (loginRequired) {
      alert(
        "Pour accéder à l'OA Scanner, veuillez utiliser les identifiants suivants :\n\n" +
        "Nom d'utilisateur: azert@azert.tn\n" +
        "Mot de passe: azert123"
      );
    }
    // Rediriger vers l'URL après avoir cliqué sur OK
    window.open(url, "_blank");
  };

  return (
    <Wrapper id="projets">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Nos Projets Formidables</h1>
            <p className="font13">
              Voici quelques-uns des projets en ligne sur lesquels nous avons travaillé. Explorez leurs captures d'écran et visitez les sites pour en savoir plus.
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            {projects.map((project, index) => (
              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4" key={index}>
                <ProjectBox>
                  <ScreenshotWrapper>
                    <img src={project.img} alt={project.title} />
                  </ScreenshotWrapper>
                  <h3 className="font20 extraBold">{project.title}</h3>
                  <p className="font13">{project.description}</p>
                  <ProjectLink
                    href="#"
                    onClick={(e) => {
                      e.preventDefault(); // Empêcher le comportement par défaut du lien
                      handleClick(project.link, project.loginRequired);
                    }}
                  >
                    Visiter le projet
                  </ProjectLink>
                </ProjectBox>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}


// Styled Components
const Wrapper = styled.section`
  width: 100%;
`;

const HeaderInfo = styled.div`
  text-align: center;
`;

const ProjectBox = styled.div`
  background-color: #f9f9f9;
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const ScreenshotWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
  margin-bottom: 20px;
  
  img {
    width: 100%;
    height: auto;
    display: block;
  }
`;

const ProjectLink = styled.a`
  display: inline-block;
  text-decoration: none;
  color: #fff;
  background-color: #1f1b59;
  margin-top:10px;
  padding: 10px;
  border-radius: 8px;
  width: 100%;
  text-align: center;
  transition: background-color 0.3s, color 0.3s;

  :hover {
    background-color: #27254BFF;
    color: #fff;
  }
`;
