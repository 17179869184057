import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import Sidebar from "../Nav/Sidebar";
import Backdrop from "../Elements/Backdrop";
import LogoIcon from "../../assets/svg/Logo";
import BurgerIcon from "../../assets/svg/BurgerIcon";
import { useLanguage } from "../../context/LanguageContext";  // Use context for language
import LanguageToggle from "../Elements/LanguageToggle";  // Import LanguageToggle

export default function EnglishNavbar() {
  const { language } = useLanguage();  // Use language context
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);

  useEffect(() => {
    const handleScroll = () => setY(window.scrollY);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [y]);

  // Set navItems based on language
  const navItems = language === 'en' ? ["Home", "Services", "Projects", "Team", "Pricing", "Contact"] : ["Accueil", "Services", "Projets", "Équipe", "Tarifs", "Contact"];

  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper className="flexCenter animate whiteBg" style={y > 100 ? { height: "70px" } : { height: "80px" }}>
        <NavInner className="container flexSpaceCenter">
          <Link className="pointer flexNullCenter" to="home" smooth={true}>
            <LogoIcon />
            <h1 style={{ marginLeft: "18px" }} className="font30 semiBold">Apptap</h1>
          </Link>
          <BurgerWrapper className="pointer" onClick={() => toggleSidebar(!sidebarOpen)}>
            <BurgerIcon />
          </BurgerWrapper>
          <UlWrapper className="flexNullCenter">
            {navItems.map((item, index) => (
              <li key={index} className="semiBold font15 pointer">
                <Link
                  activeClass="active"
                  style={{ padding: "10px 15px" }}
                  to={item.toLowerCase()}
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  {item}
                </Link>
              </li>
            ))}
            <LanguageToggle /> {/* Add LanguageToggle component */}
          </UlWrapper>
        </NavInner>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;

const NavInner = styled.div`
  position: relative;
  height: 100%;
`;

const BurgerWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;

const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;
