import React from 'react';
import logo from '../img/logow1.png';  // Adjust the path according to where you save your logo

function SvgComponent(props) {
  return (
    <img
      src={logo}
      alt="Logo"
      width={50}
      height={50}
      {...props}
    />
  );
}

export default SvgComponent;
