import React, { useState } from "react";
import styled from "styled-components";
import emailjs from "emailjs-com";

const pricingData = {
  "Web Offers": [
    {
      icon: "webDevelopment",
      price: "500 TND",
      title: "Portfolio Website",
      text: "A simple portfolio website to showcase your work, including hosting, server, and domain.",
      offers: [
        { name: "Gallery Section", cheked: true },
        { name: "Responsive Design", cheked: true },
        { name: "Contact Form", cheked: true },
        { name: "1 Revision", cheked: true },
        { name: "Hosting & Domain", cheked: true },
      ],
      action: () => alert("Portfolio Website Selected"),
    },
    {
      icon: "webDevelopment",
      price: "1000 TND",
      title: "Basic Website",
      text: "A simple and effective website for your business with essential features.",
      offers: [
        { name: "Responsive Design", cheked: true },
        { name: "Basic SEO", cheked: true },
        { name: "Contact Form", cheked: true },
        { name: "Hosting & Domain", cheked: true },
      ],
      action: () => alert("Basic Website Selected"),
    },
    {
      icon: "webDevelopment",
      price: "4500 TND",
      title: "E-commerce Website",
      text: "Launch your online store with essential features for product management.",
      offers: [
        { name: "Product Management", cheked: true },
        { name: "Payment Gateway Integration", cheked: true },
        { name: "Automated Delivery System", cheked: true },
        { name: "Custom Design", cheked: true },
        { name: "Hosting & Domain", cheked: true },
      ],
      action: () => alert("Basic E-commerce Website Selected"),
    },
  ],
  "Mobile Offers": [
    {
      icon: "mobileDevelopment",
      price: "1000 TND",
      title: "Simple Mobile App",
      text: "A straightforward mobile app for iOS or Android with basic functionality.",
      offers: [
        { name: "iOS or Android", cheked: true },
        { name: "Basic UI/UX", cheked: true },
      ],
      action: () => alert("Simple Mobile App Selected"),
    },
    {
      icon: "mobileDevelopment",
      price: "1500 TND",
      title: "Standard Mobile App",
      text: "A mobile app with enhanced features and a user-friendly interface.",
      offers: [
        { name: "iOS & Android", cheked: true },
        { name: "Push Notifications", cheked: true },
        { name: "Basic Support", cheked: true },
      ],
      action: () => alert("Standard Mobile App Selected"),
    },
    {
      icon: "mobileDevelopment",
      price: "3000 TND",
      title: "Advanced Mobile App",
      text: "A comprehensive mobile solution for both iOS and Android with custom integrations.",
      offers: [
        { name: "iOS & Android", cheked: true },
        { name: "Custom Integrations", cheked: true },
        { name: "Advanced UI/UX", cheked: true },
        { name: "Premium Support", cheked: true },
      ],
      action: () => alert("Advanced Mobile App Selected"),
    },
  ],
  "Customized Solutions": [
    {
      icon: "personalizedApps",
      price: "5000 TND",
      title: "Custom Solution",
      text: "Tailored solutions combining web and mobile, including custom features and integrations.",
      offers: [
        { name: "Web & Mobile App", cheked: true },
        { name: "Custom Features", cheked: true },
        { name: "AI Integration", cheked: true },
        { name: "Premium Support", cheked: true },
      ],
      action: () => alert("Custom Solution Selected"),
    },
  ],
  "Design Offers": [
    {
      icon: "design",
      price: "500 TND",
      title: "UI/UX Design",
      text: "Get a modern and intuitive design for your web or mobile project.",
      offers: [
        { name: "Responsive Design", cheked: true },
        { name: "User Research", cheked: true },
        { name: "Wireframes & Prototypes", cheked: true },
        { name: "2 Design Revisions", cheked: true },
      ],
      action: () => alert("UI/UX Design Selected"),
    },
  ],
};



export default function Contact() {
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [formData, setFormData] = useState({
    fname: "",
    email: "",
    subject: "",
    message: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePackageSelect = (title) => {
    setSelectedPackage(title);
    setFormData({ ...formData, subject: title });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    emailjs
      .send("service_j32vkxh", "template_cu9ljc5", {
        from_name: formData.fname,
        from_email: formData.email,
        subject: formData.subject,
        message: formData.message,
      }, "ehl4HT6h9vDth5Kb4")
      .then((response) => {
        console.log("Email sent successfully!", response.status, response.text);
        setIsSubmitted(true);
        setFormData({ fname: "", email: "", subject: "", message: "" });
      })
      .catch((error) => {
        console.error("Error sending email: ", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Wrapper id="contact">
      <div className="lightBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Let's Get in Touch</h1>
            <p className="font13">Please fill out the form below to initiate your project with us!</p>
          </HeaderInfo>
          <Row>
            <Col>
              {isSubmitted ? (
                <SuccessMessage>
                      <span role="img" aria-label="check mark">✔️</span> Your message has been sent!
                </SuccessMessage>
              ) : (
                <>
                  <PackageSelector>
                    <label className="font13">Choose Your Package:</label>
                    <select onChange={(e) => handlePackageSelect(e.target.value)} value={selectedPackage || ""}>
                      <option value="" disabled>Select a package</option>
                      {Object.values(pricingData).flat().map((pkg) => (
                        <option key={pkg.title} value={pkg.title}>
                          {pkg.title} - {pkg.price}
                        </option>
                      ))}
                    </select>
                  </PackageSelector>
                  <Form onSubmit={handleSubmit}>
                    <label className="font13">First Name:</label>
                    <input type="text" name="fname" value={formData.fname} onChange={handleInputChange} required />
                    <label className="font13">Email:</label>
                    <input type="email" name="email" value={formData.email} onChange={handleInputChange} required />
                    <label className="font13">Subject:</label>
                    <input type="text" name="subject" value={selectedPackage || ""} readOnly />
                    <label className="font13">Message:</label>
                    <textarea rows="4" name="message" value={formData.message} onChange={handleInputChange} required />
                    <SumbitWrapper>
                      <ButtonInput type="submit" value="Send Message" />
                    </SumbitWrapper>
                  </Form>
                </>
              )}
            </Col>
          </Row>
        </div>
      </div>

      {/* Loading Popup */}
      {isLoading && (
        <LoadingOverlay>
          <LoadingSpinner />
          <LoadingText>Sending...</LoadingText>
        </LoadingOverlay>
      )}
    </Wrapper>
  );
}

// Styled components
const Wrapper = styled.section`
  width: 100%;
  padding: 20px;

  @media (max-width: 600px) {
    padding: 10px;
  }
`;

const HeaderInfo = styled.div`
  padding: 30px 0;
  text-align: center;

  @media (max-width: 860px) {
    padding: 20px 0;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: center; /* Center contents horizontally */
  padding-bottom: 30px;
`;

const Col = styled.div`
  flex: 0 0 auto; /* Prevents it from stretching to full width */
  max-width: 600px; /* Set max width */
  width: 100%; /* Ensure it uses full width on smaller screens */
`;

const PackageSelector = styled.div`
  margin-bottom: 20px;

  label {
    font-size: 1rem;
  }

  select {
    width: 100%;
    padding: 10px;
    border: 1px solid #707070;
    border-radius: 4px;
    background-color: transparent;
    color: #1f1b59;
    font-size: 1rem;
  }
`;

const Form = styled.form`
  padding: 20px 0;

  label {
    font-size: 1rem;
  }

  input,
  textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #707070;
    border-radius: 4px;
    font-size: 1rem;
    box-sizing: border-box;

    @media (max-width: 600px) {
      font-size: 0.9rem;
    }
  }
`;

const SumbitWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const ButtonInput = styled.input`
  background-color: #0b093b;
  color: white;
  border: none;
  padding: 12px 20px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #23214eff;
  }
`;

const SuccessMessage = styled.div`
  padding: 20px;
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
  border-radius: 5px;
  margin-bottom: 20px;
  text-align: center;
`;

const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const LoadingSpinner = styled.div`
  border: 8px solid rgba(255, 255, 255, 0.3);
  border-top: 8px solid #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const LoadingText = styled.div`
  color: white;
  margin-top: 20px;
  font-size: 1.2rem;
`;