import React from "react";
import styled from "styled-components";
import { FaPalette, FaCode, FaMobileAlt, FaRegLightbulb } from "react-icons/fa";

export default function ServiceBox({ icon, title, subtitle }) {
  let getIcon;

  switch (icon) {
    case "design":
      getIcon = <FaPalette color="#FF6347" size={50} />;
      break;
    case "webDevelopment":
      getIcon = <FaCode color="#4682B4" size={50} />;
      break;
    case "mobileDevelopment":
      getIcon = <FaMobileAlt color="#32CD32" size={50} />;
      break;
    case "personalizedApps":
      getIcon = <FaRegLightbulb color="#FFD700" size={50} />;
      break;
    default:
      getIcon = <FaPalette color="#FF6347" size={50} />;
      break;
  }

  return (
    <Wrapper className="flex flexColumn">
      <IconStyle>{getIcon}</IconStyle>
      <TitleStyle className="font20 extraBold">{title}</TitleStyle>
      <SubtitleStyle className="font13">{subtitle}</SubtitleStyle>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;

const IconStyle = styled.div`
  margin-bottom: 20px;
  @media (max-width: 860px) {
    margin: 0 auto 20px auto;
  }
`;

const TitleStyle = styled.h2`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 40px 0;
  text-align: center;

  @media (max-width: 860px) {
    padding: 20px 0;
  }
`;

const SubtitleStyle = styled.p`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  text-align: center;
`;
