import React from "react";
import styled from "styled-components";
import FullButton from "../Buttons/FullButton";
import HeaderImage from "../../assets/img/header-img.png";
import HeaderImagem from "../../assets/img/header-imgm.png";
import QuotesIcon from "../../assets/svg/Quotes";
import Dots from "../../assets/svg/Dots";

export default function Header() {
  return (
    <Wrapper id="accueil">
      <LeftSide>
        <ContentWrapper>
          <h1 className="extraBold font60">Applications innovantes, à un seul tap !</h1>
          <HeaderP className="font13 semiBold">
            Chez AppTap, nous transformons vos idées en applications innovantes. Notre équipe d'experts allie créativité et technologie pour
            offrir des expériences numériques sans faille. Découvrez comment nous pouvons élever votre projet d'application en un seul tap.
          </HeaderP>
          <BtnWrapper>
            <FullButton title="Commencer" to="contact" />
          </BtnWrapper>
        </ContentWrapper>
      </LeftSide>
      <RightSide>
        <ImageWrapper>
          <Img className="radius8" src={HeaderImage} alt="bureau" />
          <QuoteWrapper className="flexCenter darkBg radius8">
            <QuotesWrapper>
              <QuotesIcon />
            </QuotesWrapper>
            <div>
                <p className="font15 whiteColor">
                  <em>"L'innovation est au cœur de tout ce que nous faisons. Chez AppTap, nous créons des solutions qui répondent non seulement aux besoins d'aujourd'hui mais ouvrent la voie aux possibilités de demain."</em>
                </p>
                <p className="font13 orangeColor" style={{ marginTop: '10px' }}>Vision AppTap</p>
              </div>
          </QuoteWrapper>
          <DotsWrapper>
            <Dots />
          </DotsWrapper>
        </ImageWrapper>
        <GreyDiv className="lightBg"></GreyDiv>
      </RightSide>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */

  @media (max-width: 960px) {
    flex-direction: column;
    background: url(${HeaderImagem}) no-repeat center center;
    background-size: cover;
    padding: 50px 20px;
    height: 600px !important;
  }
`;

const ContentWrapper = styled.div`
  text-align: center; /* Center text in the wrapper */
`;

const HeaderP = styled.div`
  max-width: 470px;
  margin: 0 auto; /* Center the element */
  padding: 25px 0 50px 0;
  line-height: 1.5rem;
  
  @media (max-width: 960px) {
    max-width: 700px; /* Set max-width for medium-sized screens */
    text-align: center; /* Center text on medium screens */
    color: white;
  }

  @media (max-width: 560px) {
    max-width: 300px; /* Set max-width for smaller screens */
    text-align: center; /* Ensure text is centered */
    color: white; /* Ensure text color remains white */
  }
`;


const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */

  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 80px 0 50px 0;
    background: rgba(110, 107, 107, 0.09);
    border-radius: 10px;
    padding: 0;
    h1, p {
      color: white;
    }
  }
  
`;

const RightSide = styled.div`
  width: 50%;
  height: 100%;
  
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin-top: 30px;
    display: none; /* Hide on mobile */
  }
`;

const BtnWrapper = styled.div`
  max-width: 190px;
  margin: 0 auto; /* Center button wrapper */
  
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;

const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  
  @media (max-width: 960px) {
    display: none; /* Hide on mobile */
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
    display: none; /* Hide on mobile */
  }
`;

const Img = styled.img`
  @media (max-width: 560px) {
    display: none; /* Hide on smaller screens */
  }
`;

const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  text-align: center; /* Center text */
  
  @media (max-width: 960px) {
    left: 20px;
  }
  
  @media (max-width: 560px) {
    bottom: -50px;
    max-width: 250px;
    height: 100px;
  }
`;

const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;

const DotsWrapper = styled.div`
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 2;
  
  @media (max-width: 960px) {
    right: 5px;
  }
  
  @media (max-width: 960px) {
    display: none; /* Hide on mobile */
  }
`;
