import React from 'react';
import styled from 'styled-components';

export default function TeamMemberBox({ name, position, description, imgSrc, imgSize = "150px" }) {
  return (
    <Wrapper>
      <ImageWrapper>
        <ProfileImage src={imgSrc} alt={name} imgSize={imgSize} />
      </ImageWrapper>
      <Info>
        <h3>{name}</h3>
        <p>{position}</p>
        <p>{description}</p>
      </Info>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  text-align: center;
  margin: 20px;
`;

const ImageWrapper = styled.div`
  margin-bottom: 15px;
`;

const ProfileImage = styled.img`
  width: ${(props) => props.imgSize};
  height: ${(props) => props.imgSize};
  border-radius: 50%;
  object-fit: cover;
`;

const Info = styled.div`
  h3 {
    font-size: 20px;
    margin-bottom: 5px;
  }
  p {
    font-size: 14px;
    color: #555;
  }
`;
